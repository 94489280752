<template>
	<div
		class="
			c-block-list-shortcuts-custom c-block-list-shortcuts-custom--wide
			-mt-xs
			-mx-layout-margin
		"
	>
		<div class="px-layout-margin bg-primary w-full py-lg">
			<h2 class="text-h3 text-primary-button font-semibold">
				{{ title }}
			</h2>

			<div
				class="
					bg-white
					w-full
					rounded-lg
					p-md
					mt-sm
					grid
					gap-x-xl gap-y-xs
					>=768:gap-y-xs
					grid-cols
					>=768:grid-cols-2
					>=1024:grid-cols-3
				"
			>
				<ContentLink
					v-for="(link, index) in shortcuts"
					:key="`shortcuts-${index}`"
					:to="link.url"
					:target="link.target"
				>
					{{ link.name }}
				</ContentLink>
			</div>
		</div>
	</div>
</template>

<script>
import ContentLink from '~/components/shared/ContentLink';

export default {
	name: 'BlockListShortcutsCustom',
	components: { ContentLink },
	inheritAttrs: false,

	props: {
		shortcuts: Array,
		title: String,
	},
};
</script>
